import React from 'react';
import ArticleTile from './ArticleTile';

const NewsList = ({ news }) => {
  const renderNews = () => {
    return news.map((item) => {
      return (
        <ArticleTile
          key={item.id}
          content={item.content}
          image={item.featuredImage !== null ? item.featuredImage.node.mediaItemUrl : ''}
          id={item.id}
          createdAt={item.date}
          title={item.title}
          slug={item.slug}
        />
      );
    });
  };

  return (
    <div className={`news-list ${news.length < 1 ? 'empty' : ''}`}>
      {news.length < 1 ? <h1>Brak aktualności do wyświetlenia...</h1> : renderNews()}
    </div>
  );
};

export default NewsList;

import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import 'moment/locale/pl';

const ArticleTile = ({ content, title, createdAt, image, slug }) => {
  const createPreviewText = () => {
    let pureText = content.replace(/(<([^>]+)>)/gi, '');
    pureText = pureText.replaceAll(`\n`, ' ');
    pureText.trimEnd();
    if (pureText.length > 100) {
      pureText = `${pureText.slice(0, 99)}`;
    }
    if (pureText.lastIndexOf('.') !== pureText.length - 1) {
      pureText = `${pureText.slice(0, -1)}`;
    }
    if (pureText.lastIndexOf('<') > 0) {
      pureText = pureText.substr(0, pureText.lastIndexOf('<'));
    }
    pureText = pureText.replaceAll(`&#8230;`, '...');
    return <p>{pureText}</p>;
  };

  const getLocalizedDate = () => {
    moment.locale('pl');
    const momentDate = moment(createdAt);
    return momentDate.format('lll');
  };

  return (
    <article className="article__card">
      <p className="article__date">{getLocalizedDate()}</p>
      <div className="article__body">
        <div
          className="article__background"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <Link to={`/news/${slug}/`} className="article__content">
          <h2>{title}</h2>
          <div className="markdown">
            {createPreviewText()}
            <span className="read-more">Czytaj dalej &rarr;</span>
          </div>
        </Link>
      </div>
    </article>
  );
};

export default ArticleTile;

/* eslint-disable no-use-before-define */
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import Spinner from '../components/layout/Spinner';
import NewsList from '../components/news/NewsList';

const News = () => {
  const [news, setArticles] = useState([]);

  const [fetchData, { loading }] = useLazyQuery(GET_NEWS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const newArticles = [...data.posts.nodes].filter((p) => p.gallery_control.isVisible);
      setArticles(newArticles);
    },
  });
  useEffect(() => {
    fetchData();
  }, []);

  const spinnerWrapper = (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
      <Spinner />
    </div>
  );

  return (
    <Layout>
      <SEO title="Aktualności" />
      {loading ? spinnerWrapper : <NewsList news={news} />}
    </Layout>
  );
};

export default News;

const GET_NEWS = gql`
  query GetNews {
    posts {
      nodes {
        id
        content
        title
        date
        slug
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        gallery_control {
          isVisible
        }
      }
    }
  }
`;
